import { Header } from "../../components/Header/Header";

const Dashboard = () => {
  return (
    <>
      <Header />
    </>
  );
};
export default Dashboard;
