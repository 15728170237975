import { Box, CircularProgress } from "@mui/material";

export const Spinner = () => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <CircularProgress color={`success`} size={`4rem`} />
    </Box>
  );
};
