import Logo from "../../assets/images/scoring-logo.png";
import { Link } from "react-router-dom";
import classes from "./DemoRequest.module.css";
import { translate } from "../../utils/translate";
import { useContext, useEffect, useState } from "react";
import { userContext } from "../../services/userContext";
import { makeStyles } from "@material-ui/core/styles";
import bg from "../../assets/images/BG.jpg";
import { RegistrationSteps } from "./registration-steps";

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "2rem",
  },
  header: {
    padding: "0.9rem 3rem",
    height: "fit-content",
    backgroundColor: "#232f3e",
  },
  linkRow: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "40px",
    flexWrap: "wrap",
  },
  bodyDiv: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: {
    fontSize: "22px",
    fontWeight: "bold",
    textTransform: "uppercase",
    textDecoration: "underline",
  },
  textBase: {
    fontSize: "16px", // or adjust based on your design
  },
  linkButton: {
    backgroundColor: "#aa1d23",
    color: "white",
    width: "320px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
    },
  },
  contactInfo: {
    marginTop: "45px",
    fontWeight: "lighter",
    textAlign: "center",
  },
  phoneNumber: {
    fontWeight: "medium",
    borderBottom: "1px solid",
    marginTop: "2px",
    "&:hover": {
      color: "#aa1d23",
    },
  },
  returnLink: {
    textDecoration: "underline",
    fontSize: "16px", // adjust as needed
    "&:hover": {
      color: "#aa1d23",
    },
  },
  footerLinks: {
    marginTop: "10%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
    fontSize: "16px",
    fontWeight: "normal",
  },
  footerLink: {
    margin: "0 10px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  holder: {
    marginTop: "140px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  divide: {
    "& > a:not(:last-child)": {
      position: "relative",
      "&:after": {
        width: "1px",
        height: "1em",
        content: "''",
        display: "inline-block",
        margin: "0 10px",
        backgroundColor: "black",
        position: "absolute",
        bottom: "2px",
      },
    },
  },
}));

const DemoRequest = () => {
  const { lang } = useContext(userContext);
  const classes = useStyles();

  return (
    <div className={`${classes.bg}`}>
      <div id={`header`} className={classes.header}>
        <div className={``}>
          <Link to={`/`} className={``}>
            <img src={Logo} alt="logo" width={300} height={250} />
          </Link>
        </div>
      </div>

      <div id={`bodyDiv`} className={classes.bodyDiv}>
        <div className={classes.holder}>
          <h1 className={`${classes.heading} ${classes.textBase}`}>
            ZAHTEV ZA DEMO NALOG
          </h1>
          <p className={`${classes.textBase} mt-[40px]`}>
            Ukoliko ste zainteresovani za besplatnu demo verziju portala,
            potrebno je da pokrenete proces registracije.
          </p>
          <RegistrationSteps />
          <div className={classes.contactInfo}>
            <p>
              {[
                "U cilju dodatnog informisanja, u svakom trenutku nas možete kontaktirati pozivom na broj telefona:",
              ]}
            </p>
          </div>{" "}
          <a href={`tel:0111233331`} className={classes.phoneNumber}>
            011 1233 331
          </a>
          <div className={`${classes.footerLinks}`}>
            <Link to={`/login`} className={classes.returnLink}>
              {["Vrati se na početnu stranu sajta"]}
            </Link>
            <div className={classes.divide}>
              <Link className={classes.footerLink} to={`/kontakt`}>
                {["Kontakt"]}
              </Link>
              <Link className={classes.footerLink} to={`/politika-privatnosti`}>
                {["Politika privatnosti"]}
              </Link>
              <Link className={classes.footerLink} to={`/o-nama`}>
                {["O nama"]}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoRequest;
