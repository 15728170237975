import Table from "./Table";
import { useContext, useState } from "react";
import Chart from "./Chart";
import { translate } from "../../../utils/translate";
import { userContext } from "../../../services/userContext";
import { useQuery } from "@tanstack/react-query";
import { post } from "../../../services/api";
import { useParams } from "react-router-dom";

const CompanyDiagram = ({ company_translate_data }) => {
  const { id } = useParams();
  const [code, setCode] = useState();
  const [chartData, setChartData] = useState();

  let thead_translate_data =
    company_translate_data?.["company-profile"]?.["balances"]?.["table"]?.[
      "th"
    ];
  let trow_translate_data =
    company_translate_data?.["company-profile"]?.["balances"]?.["table"]?.[
      "tr"
    ];

  const { data, isLoading: isFetching } = useQuery({
    queryKey: ["companyBasicData", id],
    queryFn: async () => {
      return await post(
        `/portal/companies/balances/list`,
        {
          id,
        },
        "PORTAL_COMPANY_BASIC_DETAILS",
      )
        .then((res) => {
          setCode(res?.code ?? res?.status);

          if (res?.code === 200) {
            const extractedData = (res?.payload ?? [])?.map((item) => {
              return {
                value: item?.["ukupna_aktiva"],
                year: item?.godina,
              };
            });

            setChartData({
              rowName: "Ukupna aktiva",
              rowSlug: "ukupna_aktiva",
              graphData: extractedData?.map((item, index) => {
                return {
                  value: item?.value,
                  year: item?.year,
                };
              }),
            });
          }
          return res?.payload;
        })
        ?.catch((err) => console.log(err));
    },
    refetchOnWindowFocus: false,
  });

  const { lang } = useContext(userContext);

  const findRowInfo = (rowSlug) => {
    let info = "";
    (data ?? [])?.map((i) => {
      info = i?.[rowSlug + "_text"];
    });
    return info;
  };
  return (
    <div className={`@container`}>
      {isFetching ? (
        <div
          className={`min-h-[15rem] max-h-[20rem] bg-slate-300 w-full h-full animate-pulse rounded-lg mt-7`}
        ></div>
      ) : (
        <div
          className={`grid grid-cols-2 ${
            code === 403 && "border"
          } bg-white shadow-xl rounded-lg gap-x-5 @[1138px]:gap-x-10 gap-y-10 @[1138px]:gap-y-10 @[1150px]:justify-between  px-[1rem] max-sm:px-[1rem] mt-7 py-[2rem]`}
        >
          {code === 403 ? (
            <p>Ovu sekciju ne možete videti.</p>
          ) : (
            <>
              <div
                className={`col-span-2 flex flex-col @[78.125rem]:col-span-1 @[93.438rem]:col-span-1`}
              >
                <Table
                  translate_data={{
                    thead: thead_translate_data,
                    trow: trow_translate_data,
                  }}
                  data={data}
                  className={`cursor-pointer hover:bg-[#2b343b] hover:text-white`}
                  setChartData={setChartData}
                  chartData={chartData}
                />

                <div
                  className={`p-3 self-stretch flex-1 overflow-y-auto mt-5 bg-[#f4f7fa] flex flex-col h-auto rounded-lg`}
                >
                  <h1 className={`font-medium`}>
                    {company_translate_data?.["company-profile"]?.[
                      "balances"
                    ]?.["explanation"]?.["selected_section"]?.["title"] ??
                      "Obrazloženje izabrane sekcije"}
                  </h1>
                  <p className={`text-sm mt-2 pl-3`}>
                    {findRowInfo(chartData?.rowSlug)}
                  </p>
                </div>
              </div>
              <div
                className={`col-span-2 rounded-xl pb-5 px-2 bg-[#2b343b] @[78.125rem]:col-span-1 @[93.438rem]:col-span-1`}
              >
                <h1 className={`text-white pt-2 pb-4 text-center`}>
                  {company_translate_data?.["company-profile"]?.["balances"]?.[
                    "chart"
                  ]?.["header"]?.["title"] ?? "Trenutno posmatrate"}
                  : {trow_translate_data?.[chartData?.rowSlug] ?? ""}
                </h1>
                <Chart chartData={chartData} />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CompanyDiagram;
