export const Section = ({ children, className }) => {
  return (
    <div className={`@container`}>
      <div
        className={`bg-white shadow-xl rounded-lg px-[1rem] max-sm:px-[1rem] mt-7 py-[2rem] ${className}`}
      >
        {children}
      </div>
    </div>
  );
};
