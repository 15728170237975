import classes from "../LoginScreen/LoginScreen.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/images/scoring-logo-login.png";
import { Input } from "../../components/LoginInput/input";
import Password from "../../assets/Icons/lock.png";
import PIN from "../../assets/Icons/PIN.png";
import { toast } from "react-toastify";
import { useContext, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { post } from "../../services/api";
import { userContext } from "../../services/userContext";
import { icons } from "../../lib/icons";

const PasswordReset = () => {
  const { lang } = useContext(userContext);
  const [modal, setModal] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  //token iz URL-a
  const token = params["token"];

  const [loading, setLoading] = useState(false);

  //objekat koji se šalje na api
  const [passwordChangeData, setPasswordChangeData] = useState({
    pin: "",
    token: token,
    password: "",
  });
  //potvrda lozinke
  const [confirmPassword, setConfirmPassword] = useState("");

  //funkcija za promenu lozinke
  const {mutate} = useMutation({
    mutationKey: [token],
    mutationFn: async (passwordChangeData) => {
      if (passwordChangeData?.password === confirmPassword) {
        return post(
          "/portal/sign-in/forgot-password-reset",
          passwordChangeData,
        ).then((res) => {
          if (res?.code === 200) {
            toast.success(["Uspešno ste promenili lozinku!"], {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
            });
            setModal(true);
            setLoading(false);
            navigate("/login");
          } else {
            toast.error(res?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
            });
            setLoading(false);
          }
        });
      } else {
        setLoading(false);
        toast.warn(["Lozinke se ne poklapaju!"], {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    },
  });
  return (
    <div className={classes.bg}>
      <div
        className={
          loading
            ? `bg-black text-white flex items-center justify-center fixed top-0 left-0 w-full h-full z-[100] opacity-50`
            : `hidden`
        }
      >
        {icons.loading}
      </div>
      <div
        className={`ml-[11.63%] bg-[#232f3e] w-[31%] 3xl:w-[28.25%] pb-[2rem] rounded-b-3xl`}
      >
        <div className={`mx-[31.67%] pt-[8.62%]`}>
          <Link to={"/"}>
            <img src={Logo} />
          </Link>
        </div>
        <form
          autoComplete={"new-password"}
          className={`mt-[9%] mx-[8%] flex flex-col xl:gap-[1.1rem] 3xl:gap-[2.5rem]`}
        >
          {/*PIN input*/}
          <Input
            key={`pin`}
            data={passwordChangeData}
            setData={setPasswordChangeData}
            image={PIN}
            type={`text`}
            isReset={true}
            name={`pin`}
            label={`Unesite PIN:`}
            id={`pin`}
          />
          {/*Password input*/}
          <Input
            key={`new-password`}
            data={passwordChangeData}
            setData={setPasswordChangeData}
            image={Password}
            type={`password`}
            isReset={true}
            name={`password`}
            label={`Nova lozinka:`}
            id={`new-password`}
          />
          <div className="relative w-full flex flex-col gap-1">
            <label
              htmlFor={"confirm-new-password"}
              className="text-white text-[.9rem] font-light"
            >
              Potvrdite novu lozinku:
            </label>
            <input
              key={`confirm-new-password`}
              id={`confirm-new-password`}
              type={`password`}
              autoComplete={"new-password"}
              name={`confirm-new-password`}
              className={`rounded-xl bg-white/30 2xl:h-[2.9rem] 3xl:h-[3.4rem] text-white placeholder:text-white pl-16 w-full focus:border-[#aa1d23] focus:ring-0`}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            <img
              src={Password}
              width={33}
              height={33}
              className="absolute invert left-4 top-[65%] transform -translate-y-1/2"
              alt=""
            />
          </div>
          <button
            className={`bg-[#aa1d23] rounded-xl uppercase text-white font-semibold hover:bg-opacity-80 xl:h-[50px] 3xl:h-[60px]`}
            onClick={(event) => {
              event.preventDefault();
              setLoading(true);
              mutate(passwordChangeData);
            }}
          >
            {["Promeni lozinku"]}
          </button>
        </form>

        <div
          className={`mt-[10%] w-full relative separate flex justify-center items-center text-white text-sm font-light`}
        >
          <Link className={`relative hover:underline`} to={`/kontakt`}>
            {["Kontakt"]}
          </Link>
          <Link
            className={`relative hover:underline`}
            to={`/politika-privatnosti`}
          >
            {["Politika privatnosti"]}
          </Link>
          <Link className={`relative hover:underline`} to={`/o-nama`}>
            {["O nama"]}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
