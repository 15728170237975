import SpiderWeb from "../../../assets/Icons/net.png";
import { translate } from "../../../utils/translate";
import { useContext } from "react";
import { userContext } from "../../../services/userContext";

const CompanyInformations = ({ company_translate_data }) => {
  const { lang } = useContext(userContext);
  return (
    <div className={`@container`}>
      <div
        className={`grid grid-cols-2 bg-white shadow-xl rounded-lg gap-x-5 @[1138px]:gap-x-10 gap-y-10 @[1138px]:gap-y-10 @[1150px]:justify-between px-[1rem] max-sm:px-[1rem] mt-7 py-[2rem]`}
      >
        <div
          className={`col-span-2 border-b border-b-[#ed545c] flex justify-between`}
        >
          <h1 className={`text-base font-semibold self-end`}>
            {company_translate_data?.["company-profile"]?.["spider-net"]?.[
              "header"
            ]?.["other_important_info"]?.["title"] ??
              "Ostale bitne informacije o kompaniji"}
          </h1>
          <div
            className={`flex py-1 rounded-t-lg items-center gap-5 bg-[#2b343b] cursor-pointer hover:bg-[#ed545c] justify-center px-10`}
          >
            <div>
              <img
                className={`invert`}
                width={25}
                height={25}
                src={SpiderWeb}
                alt=""
              />
            </div>
            <p className={`text-white`}>
              {company_translate_data?.["company-profile"]?.["spider-net"]?.[
                "header"
              ]?.["spider_net_image"]?.["text"] ?? "Paukova mreža"}
            </p>
          </div>
        </div>
        <div
          className={`col-span-2 @[78.125rem]:col-span-1 @[93.438rem]:col-span-1`}
        ></div>
      </div>
    </div>
  );
};

export default CompanyInformations;
