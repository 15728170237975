import CompanyInfoHeader from "../../../../components/CompanyInfoComponents/CompanyInfoHeader/CompanyInfoHeader";

const SpiderWeb = ({ open, company_translate_data, company_data }) => {
  return (
    <div
      className={`${
        open ? `pl-[23rem] 3xl:pl-[25rem]` : ` max-md:px-[1rem] md:pl-[3rem]`
      } mx-auto pr-[3rem] transition-all duration-500 `}
    >
      <CompanyInfoHeader
        company_translate_data={company_translate_data}
        company_data={company_data}
      />
    </div>
  );
};

export default SpiderWeb;
