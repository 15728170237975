import { icons } from "../../lib/icons";
import Chevron from "../../assets/Icons/right-chevron.png";
import { useMutation, useQuery } from "@tanstack/react-query";
import { get, post } from "../../services/api";
import profileTabs from "./data.json";
import { useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { userContext } from "../../services/userContext";
import { useTranslate } from "../../services/hooks";

export const SidebarProfile = ({ setOpen, open, translate_data, data }) => {
  const { logout } = useContext(userContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate: log_out, isPending } = useMutation({
    mutationKey: ["logout"],
    mutationFn: async () => {
      return await post(`/portal/profile/user/logout`).then((res) => {
        switch (res?.code) {
          case 200:
            logout(null, null);
            toast.success(res?.payload?.message ?? "Uspešno ste se odjavili!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
            });
            break;
          default:
            toast.error("Greška prilikom odjave!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
            });
            break;
        }
      });
    },
  });

  const [active, setActive] = useState("basic");

  useEffect(() => {
    let active_tab = searchParams?.get("tab");
    if (active_tab) {
      setActive(active_tab);
    } else {
      setActive("basic");
    }
  }, []);

  const handleTab = (param) => {
    if (param) {
      setSearchParams({ tab: param });
      setActive(param);
    }
    return null;
  };

  const { data: translate } = useTranslate("profile-menu", "PROFILE_MENU");

  return (
    <div className={`fixed top-[4.5rem] bottom-[0] left-0  max-md:hidden`}>
      <div
        className={
          open
            ? `bg-[#202831] z-[5] transition-all duration-500 translate-x-0 xl:w-[270px] 3xl:w-[320px] 4xl:w-[350px] h-full flex flex-col`
            : `bg-[#202831] -translate-x-[100%] duration-700 transition-all xl:w-[270px] 3xl:w-[320px] 4xl:w-[350px] h-full flex flex-col`
        }
      >
        <div className={`flex flex-col py-4 relative bg-[#2b343b] pl-6 pr-4`}>
          <p className={`text-white text-base`}>
            {translate?.["profile-menu"]?.["default"]?.["header"]?.[
              "welcome"
            ]?.["label"] ?? "Dobrodošli"}
            , {data?.customer_name}
          </p>
          <div
            className={`absolute -right-[0.82rem] ${
              !open && `-right-[2.5rem]`
            } top-1/2 transform -translate-y-1/2 bg-white rounded-full shadow-xl shadow-black cursor-pointer`}
            onClick={() => setOpen(!open)}
          >
            <img
              src={Chevron}
              alt={``}
              width={`23px`}
              className={`p-1 ${open ? "rotate-180" : ""}`}
            />
          </div>
        </div>
        <div className={`flex-1 overflow-y-auto customscroll`}>
          <div className={`flex flex-col gap-1`}>
            {(profileTabs ?? [])?.map(({ id, name, param }) => {
              return (
                <div
                  onClick={() => {
                    handleTab(param);
                  }}
                  key={`item-${id}`}
                  className={`flex items-center gap-4 pl-6 cursor-pointer py-2 pr-4 ${
                    active === param ? "bg-[#ed545c]" : "hover:bg-[#ed545c]"
                  }`}
                >
                  <p className={`text-white`}>
                    {translate?.["profile-menu"]?.["default"]?.["item"]?.[
                      param
                    ]?.["label"] ?? name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className={`mt-auto mb-2 pt-5 mx-6`}>
          <Button
            className={`w-full`}
            disabled={isPending}
            variant={`contained`}
            color={`error`}
            onClick={() => {
              log_out();
            }}
          >
            {translate_data?.["header"]?.["default"]?.["header"]?.[
              "my_account_logout_button"
            ]?.["label"] ?? "Odjavite se"}
          </Button>
        </div>
      </div>
    </div>
  );
};
