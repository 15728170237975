import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { Header } from "../../components/Header/Header";
import { useSuspenseQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslate } from "../../services/hooks";
import { get } from "../../services/api";

export const ExportPage = () => {
  const { token } = useParams();

  const { data: file } = useSuspenseQuery({
    queryKey: ["export", token],
    queryFn: async () => {
      return await get(`/portal/companies/export/${token}`)?.then((res) => {
        if (res?.payload) {
          return res?.payload;
        } else {
          toast.error(res?.data?.message, {
            position: "top-center",
          });
          return res?.data;
        }
      });
    },
    refetchOnWindowFocus: false,
  });

  const { data: translate_data } = useTranslate("export", "FILE_EXPORT");
  return (
    <>
      <Header />
      <div className={`px-[2.75rem]`}>
        {file?.file_name ? (
          <>
            <h2 className={`text-base mt-8`}>
              {translate_data?.["export"]?.["basic-data"]?.["header"]?.[
                "download"
              ]?.["label"] ??
                "Kliknite da dugme ispod kako biste preuzeli export-ovan fajl"}
            </h2>
            <a
              download={file?.file_name}
              name={file?.file_name}
              href={file?.file_base64}
              target={"_blank"}
              rel="noreferrer"
            >
              <Button
                disabled={!file?.file_base64 || !file?.file_name}
                color={"success"}
                variant={"contained"}
                sx={{
                  marginTop: "1.5rem",
                }}
              >
                {translate_data?.["export"]?.["basic-data"]?.["header"]?.[
                  "download_button"
                ]?.["label"] ?? "Preuzmi"}
              </Button>
            </a>
          </>
        ) : (
          <h2 className={`text-base mt-8`}>{file?.message}</h2>
        )}
      </div>
    </>
  );
};
