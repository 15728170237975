const translate = {
  en: {
    Email: "Email",
    Grad: "City",
    "Ukupna aktiva": "Total assets",
    Godina: "Year",
    Adresa: "Address",
    Delatnost: "Occupation",
    Status: "Status",
    "Unesite lozinku:": "Enter password:",
    "Prijavite se": "Login",
    "Potvrdite lozinku:": "Confirm password:",
    "Promeni lozinku": "Change password",
    Kontakt: "Contact",
    "Politika privatnosti": "Privacy policy",
    "O nama": "About us",
    "Unesite email:": "Enter email:",
    "Sačuvajte lozinku": "Save password",
    "Zaboravili ste lozinku?": "Forgot password?",
    "Prijavite se za DEMO nalog": "Apply for DEMO account",
    "ZAHTEV ZA DEMO KORISNIČKI NALOG": "DEMO ACCOUNT REQUEST",
    "Ukoliko ste zainteresovani za besplatnu demo verziju portala, potrebno je da popunite sledeću formu:":
      "If you are interested in a free demo version of the portal, you need to fill out the following form:",
    "Strano pravno lice": "Foreign legal entity",
    "Domaće pravno lice": "Domestic legal entity",
    "Fizičko lice": "Physical person",
    "U cilju dodatnog informisanja, u svakom trenutku nas možete kontaktirati pozivom na broj telefona:":
      "For additional information, you can contact us at any time by calling",
    "Vrati se na početnu stranu sajta": "Back to home page",
    "Došlo je do greške!": "An error has occurred!",
    "Pogrešan email ili lozinka!": "Wrong email or password!",
    "Uspešno ste se ulogovali!": "You have successfully logged in!",
    "Zaboravljena lozinka": "Forgot password",
    "Ukoliko ste zaboravili Vašu lozinku, potrebno je da unesete Vaš e-mail, na koji ćemo Vam proslediti link sa daljim uputstvom za promenu lozinke.":
      "If you have forgotten your password, you need to enter your e-mail, to which we will send you a link with further instructions for changing your password.",
    Pošalji: "Send",
    "Lozinke se ne poklapaju!": "Passwords do not match!",
    "Uspešno ste promenili lozinku!":
      "You have successfully changed your password!",
    "Unesite PIN:": "Enter PIN:",
    "Trenutno gledate profil": "You are currently viewing the profile",
    "Osnovni podaci": "Basic information",
    "Paukova mreža": "Spider web",
    Istorijat: "History",
    Ograničenja: "Restrictions",
    Blokade: "Blockages",
    "Tekući računi": "Current accounts",
    Scoring: "Scoring",
    Bilansi: "Balances",
    Menice: "Promissory notes",
    Izvršenja: "Enforcement",
    Suđenja: "Trials",
    Zalozi: "Pledges",
    Lizing: "Leasing",
    "Vozni park": "Fleet",
    Uvoz: "Import",
    Izvoz: "Export",
    Nekretnine: "Real estate",
    "Građevinske dozvole": "Building permits",
    Zabeležbe: "Annotations",
    Tenderi: "Tenders",
    Vesti: "News",
    Kontakti: "Contacts",
    "Pun naziv": "Full name",
    Telefon: "Telephone",
    "Datum osnivanja": "Date of establishment",
    "Kreditni limit": "Credit limit",
    Kapital: "Capital",
    Obaveze: "Obligations",
    "Ukupni prihodi": "Total income",
    "Neto rezultat": "Net result",
    "Broj zaposlenih": "Number of employees",
    "Scoring ocena": "Scoring score",
    Promena: "Change",
    "Iznosi se prikazuju u hiljadama dinara":
      "Amounts are shown in thousands of dinars",
    "Obrazloženje izabrane sekcije": "Explanation of the selected section",
    "Trenutno posmatrate": "You are currently viewing",
    "Ostale bitne informacije o kompaniji":
      "Other important information about the company",
    PDV: "VAT",
  },
  sr: {
    Email: "Email",
    PDV: "PDV",
    Grad: "Grad",
    "Ostale bitne informacije o kompaniji":
      "Ostale bitne informacije o kompaniji",
    "Trenutno posmatrate": "Trenutno posmatrate",
    "Obrazloženje izabrane sekcije": "Obrazloženje izabrane sekcije",
    "Iznosi se prikazuju u hiljadama dinara":
      "Iznosi se prikazuju u hiljadama dinara",
    Promena: "Promena",
    "Scoring ocena": "Scoring ocena",
    "Broj zaposlenih": "Broj zaposlenih",
    "Neto rezultat": "Neto rezultat",
    "Ukupni prihodi": "Ukupni prihodi",
    Obaveze: "Obaveze",
    Kapital: "Kapital",
    "Ukupna aktiva": "Ukupna aktiva",
    Godina: "Godina",
    "Kreditni limit": "Kreditni limit",
    "Datum osnivanja": "Datum osnivanja",
    Telefon: "Telefon",
    Adresa: "Adresa",
    Delatnost: "Delatnost",
    Status: "Status",
    "Pun naziv": "Pun naziv",
    "Unesite lozinku:": "Unesite lozinku:",
    "Prijavite se": "Prijavite se",
    "Potvrdite lozinku:": "Potvrdite lozinku:",
    Kontakt: "Kontakt",
    "Politika privatnosti": "Politika privatnosti",
    "O nama": "O nama",
    "Unesite email:": "Unesite email:",
    "Sačuvajte lozinku": "Sačuvajte lozinku",
    "ZAHTEV ZA DEMO KORISNIČKI NALOG": "ZAHTEV ZA DEMO KORISNIČKI NALOG",
    "Zaboravili ste lozinku?": "Zaboravili ste lozinku?",
    "Prijavite se za DEMO nalog": "Prijavite se za DEMO nalog",
    "Ukoliko ste zainteresovani za besplatnu demo verziju portala, potrebno je da popunite sledeću formu:":
      "Ukoliko ste zainteresovani za besplatnu demo verziju portala, potrebno je da popunite sledeću formu:",
    "Strano pravno lice": "Strano pravno lice",
    "Domaće pravno lice": "Domaće pravno lice",
    "Fizičko lice": "Fizičko lice",
    "U cilju dodatnog informisanja, u svakom trenutku nas možete kontaktirati pozivom na broj telefona:":
      "U cilju dodatnog informisanja, u svakom trenutku nas možete kontaktirati pozivom na broj telefona",
    "Vrati se na početnu stranu sajta": "Vrati se na početnu stranu sajta",
    "Došlo je do greške!": "Došlo je do greške!",
    "Pogrešan email ili lozinka!": "Pogrešan email ili lozinka!",
    "Uspešno ste se ulogovali!": "Uspešno ste se ulogovali!",
    "Zaboravljena lozinka": "Zaboravljena lozinka",
    "Ukoliko ste zaboravili Vašu lozinku, potrebno je da unesete Vaš e-mail, na koji ćemo Vam proslediti link sa daljim uputstvom za promenu lozinke.":
      "Ukoliko ste zaboravili Vašu lozinku, potrebno je da unesete Vaš e-mail, na koji ćemo Vam proslediti link sa daljim uputstvom za promenu lozinke.",
    Pošalji: "Pošalji",
    "Lozinke se ne poklapaju!": "Lozinke se ne poklapaju!",
    "Uspešno ste promenili lozinku!": "Uspešno ste promenili lozinku!",
    "Unesite PIN:": "Unesite PIN:",
    "Promeni lozinku": "Promeni lozinku",
    "Trenutno gledate profil": "Trenutno gledate profil",
    "Osnovni podaci": "Osnovni podaci",
    "Paukova mreža": "Paukova mreža",
    Istorijat: "Istorijat",
    Ograničenja: "Ograničenja",
    Blokade: "Blokade",
    "Tekući računi": "Tekući računi",
    Scoring: "Scoring",
    Bilansi: "Bilansi",
    Menice: "Menice",
    Izvršenja: "Izvršenja",
    Suđenja: "Suđenja",
    Zalozi: "Zalozi",
    Lizing: "Lizing",
    "Vozni park": "Vozni park",
    Uvoz: "Uvoz",
    Izvoz: "Izvoz",
    Nekretnine: "Nekretnine",
    "Građevinske dozvole": "Građevinske dozvole",
    Zabeležbe: "Zabeležbe",
    Tenderi: "Tenderi",
    Vesti: "Vesti",
    Kontakti: "Kontakti",
  },
};

export { translate };
