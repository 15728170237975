import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Chevron from "../../assets/Icons/right-chevron.png";
import SearchTable from "../../components/SearchTable/SearchTable";
import { Header } from "../../components/Header/Header";
import { useContext, useEffect, useRef, useState } from "react";
import { Input } from "../../components/shared/input";
import cols from "./columns.json";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { post } from "../../services/api";
import { Pagination } from "../../components/shared/pagination";
import { userContext } from "../../services/userContext";
import { useExport, useFilterNames, useTranslate } from "../../services/hooks";
import Filters from "../../assets/Icons/filter.png";
import { translate } from "../../utils/translate";

const rootStyle = {
  marginLeft: "2.75rem",
  marginRight: "2.75rem",
  marginTop: "1.25rem",
  backgroundColor: "#232f3e",
  borderRadius: "0.375rem",
  padding: "0.45rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const headingContainerStyle = {
  display: "flex",
  alignItems: "center",
  gap: "0.75rem",
  marginLeft: "1.25rem",
};

const chevronIconStyle = {
  width: "1.125rem",
  height: "1.125rem",
  filter: "invert(100%)",
};

const titleStyle = {
  color: "white",
  textTransform: "uppercase",
  fontWeight: "bold",
  fontSize: "1rem",
};

const subTitleStyle = {
  color: "white",
  fontSize: "1rem",
};

const filterContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "1rem",
  marginLeft: "2.75rem",
  marginRight: "2.75rem",
  borderRadius: "0.375rem",
};

const filterItemsStyle = {
  display: "flex",
  flexWrap: "wrap",
  marginLeft: "1.25rem",
  alignItems: "center",
  gap: "0.55rem",
};

const filterTextStyle = {
  color: "#000",
  cursor: "pointer",
  fontWeight: "500",
  fontSize: "1rem",
};

const showTextStyle = {
  color: "#aa1d23",
  cursor: "pointer",
  fontWeight: "500",
  fontSize: "1rem",
};

const exportTextStyle = {
  color: "#aa1d23",
  fontWeight: "500",
  fontSize: "1rem",
};

const dropdownStyle = {
  position: "relative",
  cursor: "pointer",
};

const dropdownContentStyle = {
  position: "absolute",
  minWidth: "180px",
  border: "1px solid",
  backgroundColor: "white",
  padding: "0.75rem",
  borderRadius: "0.375rem",
  top: "calc(100% + 10px)",
  right: 0,
  zIndex: 20,
};

export const SearchPage = () => {
  const [params, setParams] = useSearchParams();
  const search = params.get("search");
  const filters = params.get("filters");
  const sort_tmp = params.get("sort");
  const limit_tmp = Number(params.get("limit"));
  const page_tmp = Number(params.get("page"));

  const [sort, setSort] = useState(
    sort_tmp?.split("::")?.map((sort) => {
      const [field, direction] = sort?.split(":");
      return {
        field: field,
        direction: direction,
      };
    }) ?? [],
  );
  const [page, setPage] = useState(page_tmp > 0 ? page_tmp : 1);
  const [limit, setLimit] = useState(limit_tmp > 0 ? limit_tmp : 30);
  const [columns, setColumns] = useState(cols);

  const findSelectedFilter = (all_filters, column) => {
    let selected_filter = {};
    all_filters?.forEach((filter) => {
      filter?.fields?.forEach((field) => {
        field?.filters?.forEach((f) => {
          if (f?.filter_code === column) {
            selected_filter = f;
          }
        });
      });
    });
    return selected_filter;
  };

  const {
    data: all_filters,
    refetch,
    isLoading: is_loading_filters,
  } = useQuery({
    queryKey: ["all_filters"],
    queryFn: async () => {
      return await post(`/portal/companies/search/filters/list`, {
        group: "napredna-pretraga-1",
      }).then((res) => res?.payload);
    },
    enabled: true,
  });
  const selected_filters = filters?.split("::");

  const [selected_filters_arr, set_selected_filters_arr] = useState();
  const [tempSelectedFilters, setTempSelectedFilters] =
    useState(selected_filters_arr);

  useEffect(() => {
    set_selected_filters_arr(
      selected_filters?.map((filter) => {
        const [column, selected] = filter?.split("=");

        let selected_filter = findSelectedFilter(all_filters, column);

        const selected_values_tmp = (selected ?? ",")?.split(",");

        return {
          filter_code: column,
          table_field: selected_filter?.table_field,
          value: {
            selected: selected_values_tmp?.map((i) => i),
          },
        };
      }),
    );
    setTempSelectedFilters(
      selected_filters?.map((filter) => {
        const [column, selected] = filter?.split("=");
        const selected_values_tmp = (selected ?? ",")?.split(",");
        let selected_filter = findSelectedFilter(all_filters, column);

        return {
          filter_code: column,
          table_field: selected_filter?.table_field,

          value: {
            selected: selected_values_tmp?.map((i) => i),
          },
        };
      }),
    );
  }, [filters]);

  const selected_sort = sort_tmp?.split("::");

  const selected_sort_arr = selected_sort?.map((sort) => {
    const [field, direction] = sort?.split(":");
    return {
      field: field,
      direction: direction,
    };
  });

  const [optionOpen, setOptionOpen] = useState(false);

  const onChange = (e) => {
    const newColumns = columns?.map((column) => {
      if (column?.column === "kolone") {
        return {
          ...column,
          options: column?.options?.map((option) => {
            if (option?.prop_name === e.target.value) {
              return {
                ...option,
                visible: !option?.visible,
              };
            } else {
              return option;
            }
          }),
        };
      } else {
        return column;
      }
    });
    setColumns(newColumns);

    if (typeof window !== "undefined") {
      localStorage.setItem("columns", JSON.stringify(newColumns));
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const columns = JSON?.parse(localStorage.getItem("columns"));
      if (columns) {
        setColumns(columns);
      }
    }
  }, []);

  const { logout } = useContext(userContext);
  const { search: searched_data } = useLocation();
  const { data: companies_list, isLoading } = useQuery({
    queryKey: [
      "companies_list",
      {
        filters: selected_filters_arr,
        limit: limit,
        search: search,
        sort: selected_sort_arr ?? sort,
        page: page_tmp,
      },
    ],
    queryFn: async () => {
      return await post(`/portal/companies/search/list`, {
        search: search,
        page: page_tmp > 0 ? page_tmp : 1,
        filters: selected_filters_arr ?? [],
        limit: limit,
        sort: selected_sort_arr ?? sort,
      })
        .then((res) => res?.payload)
        .catch((err) => {});
    },
    refetchOnWindowFocus: false,
  });

  const firstCharUpperCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    if (page_tmp > companies_list?.pagination?.total_pages) {
      const handleSetParams = () => {
        const newParams = {};
        if (limit) newParams.limit = limit;
        if (sort_tmp) newParams.sort = sort_tmp;
        if (filters) newParams.filters = filters;
        if (search) newParams.search = search;
        newParams.page = companies_list?.pagination?.total_pages;
        setParams(newParams);
      };
      handleSetParams();
    }
  }, [page_tmp, limit, companies_list?.pagination?.total_pages]);

  const option_ref = useRef();
  const edit_ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      //kad se klikne izvan diva sa kolonama, zatvaramo dropdown, ima id columns
      if (
        option_ref.current &&
        !option_ref.current.contains(event.target) &&
        event.target.closest("#columns") === null
      ) {
        setOptionOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionOpen]);

  const [fieldType, setFieldType] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      //kad se klikne izvan diva sa kolonama, zatvaramo dropdown, ima id columns
      if (
        edit_ref.current &&
        !edit_ref.current.contains(event.target) &&
        event.target.closest("#edit") === null
      ) {
        setFieldType(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [fieldType]);

  const handleInPlaceChange = ({ target: { value, name } }) => {
    setTempSelectedFilters((prev) => {
      const index = prev.findIndex(({ filter_code }) => filter_code === name);
      if (index === -1) {
        return [
          ...prev,
          {
            filter_code: name,
            value: {
              selected: Boolean(value) ? [value] : [],
            },
          },
        ];
      }

      const newFilters = [...prev];
      newFilters[index] = {
        filter_code: name,
        value: {
          selected: Boolean(value) ? [value] : [],
        },
      };

      if (
        newFilters[index]?.value?.selected[0]?.length < 1 ||
        newFilters[index]?.value?.selected[0] === "" ||
        newFilters[index]?.value?.selected?.length === 0
      ) {
        newFilters.splice(index, 1);
      }

      return newFilters;
    });
  };

  const renderInPlaceInput = (field_type, data) => {
    let filter = findSelectedFilter(all_filters, field_type);
    if (filter && fieldType) {
      return (
        <div
          // ref={edit_ref}
          id={`edit`}
          className={`absolute shadow top-[4.65rem] border left-0 w-[400px] bg-white p-4 rounded-lg z-[51]`}
        >
          <Input
            key={filter?.filter_code}
            filter={filter}
            onChange={handleInPlaceChange}
            tempSelectedFilters={tempSelectedFilters}
          />
          <Button
            color={`success`}
            variant={`contained`}
            className={`!mt-2 w-full`}
            onClick={() => {
              setFieldType(null);
              set_selected_filters_arr(tempSelectedFilters);
              handleNavigate();
            }}
          >
            {translate_data?.["search"]?.["default"]?.["header"]?.[
              "filters_apply_change"
            ]?.["label"] ?? "Primeni izmenu"}
          </Button>
        </div>
      );
    }
  };

  const updateURLQuery = (page, selectedFilters, search) => {
    let page_tmp;
    let filters_tmp;
    let search_tmp;

    if (selectedFilters?.length > 0) {
      filters_tmp = selectedFilters
        ?.map(({ filter_code, value: { selected } }) => {
          const selectedValues = selected?.join(",");
          return `${filter_code}=${selectedValues}`;
        })
        .join("::");
    } else {
      filters_tmp = "";
    }

    if (page > 1 && selectedFilters?.length > 0) {
      page_tmp = 1;
    } else {
      page_tmp = page;
    }

    if (search !== "") {
      search_tmp = search;
    } else {
      search_tmp = "";
    }

    return { search_tmp, page_tmp, filters_tmp };
  };
  const [queryString, setQueryString] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const { search_tmp, filters_tmp, page_tmp } = updateURLQuery(
      page,
      tempSelectedFilters,
      search,
    );

    const generateQueryString = (
      filters_tmp,
      search_tmp,
      limit_tmp,
      page_tmp,
    ) => {
      setQueryString(
        `?${filters_tmp ? `filters=${filters_tmp}` : ""}${
          filters_tmp && (search_tmp || page_tmp || limit_tmp) ? "&" : ""
        }${search_tmp ? `search=${search_tmp}` : ""}${
          search_tmp && (page_tmp || limit_tmp) ? "&" : ""
        }${limit_tmp ? `limit=${limit_tmp}` : ""}${
          limit_tmp && page_tmp ? "&" : ""
        }${page_tmp ? `page=${page_tmp}` : ""}`,
      );
    };

    generateQueryString(filters_tmp, search_tmp, limit_tmp, page_tmp);
  }, [tempSelectedFilters, search, limit_tmp, page]);

  const handleNavigate = () => {
    navigate(`/pretraga${queryString}`);
  };

  useEffect(() => {
    if (fieldType) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [fieldType]);

  const findFilterName = (all_filters, filter_code) => {
    return all_filters?.map(({ fields }) => {
      return fields?.map(({ filters }) => {
        return filters?.map(({ filter_code: code, name }) => {
          if (code === filter_code) {
            return name;
          }
        });
      });
    });
  };

  const {
    data: translate_data,
    isPending: is_pending,
    isSuccess,
  } = useTranslate("search", "SEARCH");

  const { mutate: exportData, isPending } = useExport();

  return (
    <>
      <Header />
      <div style={rootStyle}>
        <div style={headingContainerStyle}>
          <div>
            <img src={Chevron} alt="chevron" style={chevronIconStyle} />
          </div>
          <Typography variant="h6" style={titleStyle}>
            {translate_data?.["search"]?.["default"]?.["header"]?.["filters"]?.[
              "title"
            ] ?? "Pretraga pravnih lica"}
          </Typography>
        </div>
      </div>
      <div style={filterContainerStyle}>
        <div style={filterItemsStyle}>
          {search && (
            <div
              className={`!relative group flex flex-col shadow border px-3 py-1 rounded-lg hover:border-[rgb(35,47,62)]`}
              style={filterTextStyle}
            >
              <div className={`relative group`}>
                <div
                  onClick={() => {
                    //gasimo search
                    const newParams = {};
                    if (limit) newParams.limit = limit;
                    if (sort) newParams.sort = sort;
                    if (filters) newParams.filters = filters;
                    if (page) newParams.page = page;
                    setParams(newParams);
                  }}
                  className={`group-hover:absolute group-hover:block -top-[1.35rem] w-fit right-0 left-0 mx-auto text-center hidden z-[5] bg-white hover:bg-red-500 transition-all duration-300 hover:!text-white shadow rounded-lg px-3 py-0.5 cursor-pointer`}
                >
                  <span className={`text-sm`}>X</span>
                </div>
              </div>
              <Typography
                variant="body1"
                className={`!text-sm border-b`}
                style={filterTextStyle}
              >
                {translate_data?.["search"]?.["default"]?.["header"]?.[
                  "filters_criteria"
                ]?.["label"] ?? "Kriterijum"}
              </Typography>
              <div className={`flex gap-2`}>
                <div
                  className={`flex my-1 gap-2 shadow items-center px-3 py-0.5 bg-gray-100 rounded-lg `}
                >
                  <Typography variant="body1" className={`!text-sm`}>
                    {search}
                  </Typography>
                </div>
              </div>
            </div>
          )}
          {(selected_filters_arr ?? [])?.map(
            ({ filter_code, value: { selected } }, index) => (
              <div className={`relative`}>
                <div className={`relative group`} key={filter_code}>
                  <div
                    onClick={() => {
                      //izbacujemo iz niza taj filter
                      set_selected_filters_arr((prev) => {
                        return prev.filter(
                          (item) => item?.filter_code !== filter_code,
                        );
                      });
                      setTempSelectedFilters((prev) => {
                        return prev.filter(
                          (item) => item?.filter_code !== filter_code,
                        );
                      });
                      const { filters_tmp } = updateURLQuery(
                        page,
                        tempSelectedFilters?.filter(
                          (item) => item?.filter_code !== filter_code,
                        ),
                        search,
                      );

                      const newParams = {};
                      if (filters_tmp) newParams.filters = filters_tmp;
                      if (search) newParams.search = search;
                      if (page) newParams.page = page;
                      if (limit) newParams.limit = limit;
                      if (sort?.length > 0) {
                        newParams.sort = sort
                          ?.map((s) => {
                            return `${s?.field}:${s?.direction}`;
                          })
                          .join("::");
                      }
                      setParams(newParams);
                    }}
                    className={`group-hover:absolute group-hover:block -top-[1.35rem] w-fit right-0 left-0 mx-auto text-center hidden z-[5] bg-white hover:bg-red-500 transition-all duration-300 hover:!text-white shadow rounded-lg px-3 py-0.5 cursor-pointer ${
                      filter_code === "drzava" && "!hidden"
                    }`}
                  >
                    <span className={`text-sm`}>X</span>
                  </div>
                  <div
                    key={index}
                    className={`!relative flex flex-col shadow divide-y border px-3 py-1 rounded-lg ${
                      fieldType === filter_code && "border-[rgb(35,47,62)]"
                    } hover:border-[rgb(35,47,62)]`}
                    style={filterTextStyle}
                    onClick={() => {
                      setFieldType(filter_code);
                      refetch();
                    }}
                  >
                    <Typography
                      variant="body1"
                      className={`!text-sm`}
                      style={filterTextStyle}
                    >
                      {findFilterName(all_filters, filter_code)}
                    </Typography>
                    <div className={`flex gap-2`}>
                      {selected?.map((value, index) => (
                        <div
                          key={index}
                          className={`flex my-1 gap-2 shadow items-center px-3 py-0.5 bg-gray-100 rounded-lg `}
                        >
                          <Typography variant="body1" className={`!text-sm`}>
                            <FilterValueName
                              value={value}
                              filter_code={filter_code}
                            />
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {fieldType === filter_code &&
                !is_loading_filters &&
                fieldType !== null
                  ? renderInPlaceInput(filter_code, all_filters)
                  : null}
              </div>
            ),
          )}

          <div
            onClick={() => {
              const button = document.getElementById("modalOpen");
              if (button) {
                button?.click();
              }
            }}
            className={`relative rounded-lg px-3 py-2 m-auto shadow cursor-pointer w-[3.375rem] flex items-center justify-center border group hover:bg-[rgb(35,47,62)]`}
          >
            <img
              src={Filters}
              className={`p-[0.1rem] w-[50px] group-hover:invert`}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            justifyContent: "flex-end",
          }}
        >
          <div className={`flex items-center gap-3`}>
            <Typography variant="body1" style={showTextStyle}>
              {translate_data?.["search"]?.["default"]?.["header"]?.[
                "filters_show_rows"
              ]?.["label"] ?? "Prikaži"}
              :
            </Typography>
            <FormControl sx={{ minWidth: 50 }}>
              <Select
                sx={{
                  "& .MuiSelect-select": {
                    p: "8px",
                  },
                  //focus
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgb(170, 29, 35)",
                  },
                }}
                value={limit}
                onChange={(e) => {
                  setLimit(e?.target?.value);
                  const newParams = {};
                  if (limit) newParams.limit = e.target.value;
                  if (sort) newParams.sort = sort;
                  if (filters) newParams.filters = filters;
                  if (search) newParams.search = search;
                  if (page_tmp > companies_list?.pagination?.total_pages) {
                    newParams.page = companies_list?.pagination?.total_pages;
                  } else {
                    newParams.page = page_tmp;
                  }

                  setParams(newParams);
                }}
                displayEmpty
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={dropdownStyle}>
            <Typography
              variant="body1"
              className={`cursor-pointer`}
              style={showTextStyle}
              onClick={() => setOptionOpen(!optionOpen)}
              ref={option_ref}
            >
              {translate_data?.["search"]?.["default"]?.["header"]?.[
                "filters_columns"
              ]?.["label"] ?? "Kolone"}{" "}
            </Typography>
            {optionOpen && (
              <div id={`columns`} style={dropdownContentStyle}>
                {(columns ?? [])?.map((option, index) => (
                  <Input
                    key={index}
                    filter={option}
                    onChange={onChange}
                    translate_data={
                      translate_data?.["search"]?.["default"]?.["table"]?.["th"]
                    }
                  />
                ))}
              </div>
            )}
          </div>
          <Typography
            className={`cursor-pointer ${isPending ? "opacity-80 text-gray-500" : ""}`}
            variant="h6"
            style={exportTextStyle}
            onClick={() => {
              exportData({
                selected_sort: selected_sort_arr,
                selected_filters: selected_filters_arr,
                search: search,
                selected_columns: columns,
              });
            }}
          >
            {translate_data?.["search"]?.["default"]?.["header"]?.[
              "filters_export_to_xls"
            ]?.["label"] ?? "Izvoz u XLS"}
          </Typography>
        </div>
      </div>
      <Box
        sx={{
          marginLeft: "auto",
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "2.75rem",
        }}
      >
        {companies_list?.amount_display_notification && (
          <Typography variant={`subtitle1`}>
            <span className={`text-red-500 text-sm`}>*</span>
            {companies_list?.amount_display_notification}
          </Typography>
        )}
      </Box>
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            paddingLeft: "2.75rem",
            paddingRight: "2.75rem",
          }}
        >
          {Array.from({ length: 10 }).map((_, index) => {
            return (
              <Box
                key={index}
                className={`animate-pulse rounded-lg`}
                sx={{
                  mt: 2,
                  width: "100%",
                  background: "#ccc",
                  padding: "1rem",
                  borderBottom: "1px solid #ccc",
                }}
              ></Box>
            );
          })}
        </Box>
      ) : (
        <>
          {companies_list?.items?.length > 0 ? (
            <>
              <SearchTable
                sort={sort}
                setSort={setSort}
                limit={limit}
                columns={columns}
                data={companies_list?.items}
                translate_data={
                  translate_data?.["search"]?.["default"]?.["table"]?.["th"]
                }
              />
              <Box>
                <Pagination
                  translate_data={translate_data}
                  count={companies_list?.count}
                  page={page}
                  pagination={companies_list?.pagination}
                  setPage={setPage}
                />
              </Box>
            </>
          ) : (
            <Box sx={{ marginX: "2.75rem", paddingTop: "3rem" }}>
              <Typography variant="h6" style={{ color: "black" }}>
                {translate_data?.["search"]?.["default"]?.["table"]?.[
                  "no_results"
                ]?.["label"] ?? "Nema rezultata"}
              </Typography>
            </Box>
          )}
        </>
      )}
      {fieldType !== null && (
        <div
          onClick={() => {
            setFieldType(null);
            setTempSelectedFilters(selected_filters_arr);
          }}
          className={`fixed top-0 left-0 right-0 bottom-0 bg-transparent z-50`}
        ></div>
      )}
    </>
  );
};

const FilterValueName = ({ filter_code, value }) => {
  let ddl_slug = "";
  switch (true) {
    case filter_code === "region":
      ddl_slug = "regions";
      break;
    case filter_code === "drzava":
      ddl_slug = "countries";
      break;
    case filter_code === "delatnost":
      ddl_slug = "activities";
      break;
    case filter_code === "opstina":
      ddl_slug = "municipalities";
      break;
    case filter_code === "mesto":
      ddl_slug = "places";
      break;
    case filter_code === "adresa":
      ddl_slug = "addresses";
      break;
    case filter_code === "ima-telefon":
      ddl_slug = "has-phone";
      break;
    case filter_code === "ima-email":
      ddl_slug = "has-email";
      break;
    case filter_code === "status-firme":
      ddl_slug = "status";
      break;
    case filter_code === "pravna-forma":
      ddl_slug = "legal-forms";
    case filter_code === "pretraga-baze":
      ddl_slug = "database-search";
      break;
  }

  let res = "";
  const { data } = useFilterNames(ddl_slug, value);

  if (data?.[0]?.name) {
    res = data?.[0]?.name;
  } else {
    res = value;
  }
  return res;
};
