import { Suspense, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Input } from "../shared/input";
import { useMutation, useQuery } from "@tanstack/react-query";
import { post } from "../../services/api";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import { Spinner } from "../shared/spinner";
import { useTranslate } from "../../services/hooks";

export const AdvancedSearch = ({ open, setOpen }) => {
  const [params, setParams] = useSearchParams();
  const search = params?.get("search");
  const filters_url = params?.get("filters");
  const limit = params.get("limit");
  const [selectedFilters, setSelectedFilters] = useState([
    {
      filter_code: "status-firme",
      value: {
        selected: [1],
      },
    },
  ]);
  const page = params.get("page");

  useEffect(() => {
    if (filters_url) {
      const filters_from_url = (filters_url ?? "::")
        ?.split("::")
        ?.map((filter) => {
          const [column, selected] = (filter ?? "=")?.split("=");
          return {
            filter_code: column,
            value: {
              selected: selected.split(","),
            },
          };
        });
      setSelectedFilters(filters_from_url);
    } else {
      setSelectedFilters([
        {
          filter_code: "status-firme",
          value: {
            selected: [1],
          },
        },
      ]);
    }
  }, [filters_url]);

  const navigate = useNavigate();

  //pravimo query string izabranih filtera

  const updateURLQuery = (page, selectedFilters, search) => {
    let page_tmp;
    let filters_tmp;
    let search_tmp;

    if (selectedFilters?.length > 0) {
      filters_tmp = selectedFilters
        ?.map((filter) => {
          const selectedValues = filter?.value?.selected?.join(",");
          return `${filter?.filter_code}=${selectedValues}`;
        })
        .join("::");
    } else {
      filters_tmp = "";
    }

    if (page > 1 && selectedFilters?.length > 0) {
      page_tmp = 1;
    } else {
      page_tmp = page;
    }

    if (search !== "") {
      search_tmp = search;
    } else {
      search_tmp = "";
    }

    return { search_tmp, page_tmp, filters_tmp };
  };
  const limit_tmp = params.get("limit");

  const [queryString, setQueryString] = useState("");

  useEffect(() => {
    const { search_tmp, filters_tmp, page_tmp } = updateURLQuery(
      page,
      selectedFilters,
      search,
    );

    const generateQueryString = (
      filters_tmp,
      search_tmp,
      limit_tmp,
      page_tmp,
    ) => {
      setQueryString(
        `?${filters_tmp ? `filters=${filters_tmp}` : ""}${
          filters_tmp && (search_tmp || page_tmp || limit_tmp) ? "&" : ""
        }${search_tmp ? `search=${search_tmp}` : ""}${
          search_tmp && (page_tmp || limit_tmp) ? "&" : ""
        }${limit_tmp ? `limit=${limit_tmp}` : ""}${
          limit_tmp && page_tmp ? "&" : ""
        }${page_tmp ? `page=${page_tmp}` : ""}`,
      );
    };

    generateQueryString(filters_tmp, search_tmp, limit_tmp, page_tmp);
  }, [selectedFilters, search, limit_tmp, page]);

  const handleNavigate = () => {
    navigate(`/pretraga${queryString}`);
    setOpen(false);
  };

  const handleReset = () => {
    setSelectedFilters([
      {
        filter_code: "status-firme",
        value: {
          selected: [1],
        },
      },
    ]);
    search ? navigate(`/pretraga?search=${search}`) : navigate(`/pretraga`);
    setOpen(false);
  };
  const onChange = ({ target: { value, name } }) => {
    if (value || value === 0) {
      setSelectedFilters((prev) => {
        const index = prev?.findIndex((filter) => filter?.filter_code === name);
        if (index === -1) {
          return [
            ...prev,
            {
              filter_code: name,
              value: {
                selected: Boolean(value) || value === 0 ? [value] : [],
              },
            },
          ];
        }

        const newFilters = [...prev];
        newFilters[index] = {
          filter_code: name,
          value: {
            selected: Boolean(value) || value === 0 ? [value] : [],
          },
        };

        if (
          newFilters[index]?.value?.selected[0]?.length < 1 ||
          newFilters[index]?.value?.selected[0] === "" ||
          newFilters[index]?.value?.selected?.length === 0
        ) {
          newFilters.splice(index, 1);
        }

        return newFilters;
      });
    } else {
      let filter_index = selectedFilters.findIndex(
        (filter) => filter.filter_code === name,
      );

      if (filter_index !== -1) {
        setSelectedFilters((prev) => {
          const index = prev?.findIndex(
            (filter) => filter?.filter_code === name,
          );
          if (index !== -1) {
            const newFilters = [...prev];
            newFilters.splice(index, 1);
            return newFilters;
          }
        });
      } else {
        return;
      }
    }
  };

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ["filters"],
    queryFn: async () => {
      return await post(`/portal/companies/search/filters/list`, {
        group: "napredna-pretraga-1",
      }).then((res) => {
        if (res) {
          return res?.payload;
        }
      });
    },
  });

  const {
    data: translate_data,
    isPending: is_pending,
    isSuccess,
  } = useTranslate("search-modal", "SEARCH_MODAL");

  return (
    <div
      id={"advanced-search"}
      data-open={open}
      onClick={(e) => {
        if (e.target === e.currentTarget) setOpen(false);
      }}
      className={
        open
          ? `h-[100dvh] w-full bg-black bg-opacity-50 fixed top-0 left-0 right-0 bottom-0 z-50 flex flex-col items-center justify-center`
          : `hidden`
      }
    >
      <div
        className={`bg-white max-h-[80%] my-auto overflow-y-scroll max-w-[80%] mx-auto pt-10 pl-10 pr-10`}
      >
        <div className={`flex items-center justify-between pb-5`}>
          <h2 className={`text-2xl font-semibold  text-left`}>
            {translate_data?.["search-modal"]?.["default"]?.["header"]?.[
              "modal"
            ]?.["title"] ?? "Napredna pretraga"}
          </h2>
          <p
            className={`hover:text-red-500 cursor-pointer text-2xl`}
            onClick={() => setOpen(false)}
          >
            x
          </p>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          (data ?? [])?.map(({ fields }, i) => {
            return fields?.map(({ name, filters }) => {
              return (
                <Accordion key={name} defaultExpanded={i === 0}>
                  <AccordionSummary>
                    <h3>{name}</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={`grid grid-cols-2 gap-5`}>
                      {(filters ?? [])?.map((filter, { column_name }) => {
                        return (
                          <Input
                            filter={filter}
                            onSubmit={handleNavigate}
                            tempSelectedFilters={selectedFilters}
                            onChange={onChange}
                            key={column_name}
                            translate_data={translate_data}
                          />
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            });
          })
        )}
        <div
          className={`py-5 flex w-full z-10 left-0 right-0 mt-[1rem] items-center justify-end gap-5 sticky bottom-0 bg-white`}
        >
          {/*<Button variant="contained" color="warning">*/}
          {/*  Sačuvaj pretragu*/}
          {/*</Button>*/}
          {/*  TODO: implementirati funkcionalnost, gadjace se API da sacuva pretragu*/}
          <Button variant="contained" color="error" onClick={handleReset}>
            {translate_data?.["search-modal"]?.["default"]?.["footer"]?.[
              "reset_button"
            ]?.["label"] ?? "Resetuj filtere"}
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handleNavigate();
            }}
          >
            {translate_data?.["search-modal"]?.["default"]?.["footer"]?.[
              "search_button"
            ]?.["label"] ?? "Pretraži"}
          </Button>
        </div>
      </div>
    </div>
  );
};
