import { Link, useNavigate, useParams } from "react-router-dom";
import classes from "../DemoRequest.module.css";
import Logo from "../../../assets/images/scoring-logo.png";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { post } from "../../../services/api";
import { toast } from "react-toastify";
import DemoRequestFormPhysical from "./DemoRequestFormPhysical";
import DemoRequestFormDomestic from "./DemoRequestFormDomestic";
import DemoRequestFormForeign from "./DemoRequestFormForeign";
import DemoRequestSuccess from "./DemoRequestSuccess";
import { userContext } from "../../../services/userContext";

const DemoRequestForm = () => {
  const params = useParams();
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [checkedPolicy, setCheckedPolicy] = useState(false);
  const { lang } = useContext(userContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formDataPhysical, setformDataPhysical] = useState({
    name: "",
    email: "",
    country_id: 1,
    city_name: "",
    postal_code: "",
    demo_account: "",
    birthday: "",
    gender: "",
    jmbg: "",
    mobile: "",
    document_number: "",
    accept_terms: "0",
    accept_policy: "0",
  });
  const [formDataDomestic, setformDataDomestic] = useState({
    name: "",
    email: "",
    country_id: 1,
    city_name: "",
    address: "",
    phone: null,
    company_work_position: "",
    company_contact_name: "",
    pib: null,
    maticni_broj: "",
    postal_code: "",
    mobile: "",
    accept_terms: "0",
    accept_policy: "0",
  });
  const [formDataForeign, setformDataForeign] = useState({
    name: "",
    email: "",
    country_id: 1,
    city_name: "",
    address: "",
    phone: null,
    company_work_position: "",
    company_contact_name: "",
    pib: null,
    maticni_broj: "",
    postal_code: "",
    mobile: "",
    accept_terms: "0",
    accept_policy: "0",
  });

  useEffect(() => {
    switch (params["*"]) {
      case "fizicko-lice": {
        setformDataPhysical((prevformDataPhysical) => ({
          ...prevformDataPhysical,
          accept_terms: checkedTerms ? "1" : "0",
          accept_policy: checkedPolicy ? "1" : "0",
        }));
        break;
      }
      case "domace-pravno-lice": {
        setformDataDomestic((prevformDataDomestic) => ({
          ...prevformDataDomestic,
          accept_terms: checkedTerms ? "1" : "0",
          accept_policy: checkedPolicy ? "1" : "0",
        }));
        break;
      }
      case "strano-pravno-lice": {
        setformDataForeign((prevformDataForeign) => ({
          ...prevformDataForeign,
          accept_terms: checkedTerms ? "1" : "0",
          accept_policy: checkedPolicy ? "1" : "0",
        }));
        break;
      }
    }
  }, [checkedTerms, checkedPolicy]);

  const handleInputChange = (e) => {
    switch (params["*"]) {
      case "fizicko-lice": {
        setformDataPhysical({
          ...formDataPhysical,
          [e.target.name]: e.target.value,
        });
        break;
      }
      case "domace-pravno-lice": {
        setformDataDomestic({
          ...formDataDomestic,
          [e.target.name]: e.target.value,
        });
        break;
      }
      case "strano-pravno-lice": {
        setformDataForeign({
          ...formDataForeign,
          [e.target.name]: e.target.value,
        });
        break;
      }
    }
  };

  const [formData, setFormData] = useState(
    params["*"] === "fizicko-lice"
      ? formDataPhysical
      : params["*"] === "domace-pravno-lice"
        ? formDataDomestic
        : params["*"] === "strano-pravno-lice"
          ? formDataForeign
          : null,
  );

  const [endpoint, setEndpoint] = useState(null);

  useEffect(() => {
    switch (params["*"]) {
      case "fizicko-lice":
        setEndpoint("/portal/registration/personally");
        break;
      case "domace-pravno-lice":
        setEndpoint("/portal/registration/business-domestic");
        break;
      case "strano-pravno-lice":
        setEndpoint("/portal/registration/business-foreign");
      default:
        break;
    }
  }, [params["*"]]);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = useMutation({
    mutationKey: ["demo-request", params["*"]],
    mutationFn: async (formData) => {
      return await post(endpoint, formData).then((data) => {
        if (data?.code === 200) {
          toast.success("Uspešno ste poslali zahtev za demo nalog!", {
            position: "top-center",
            autoClose: 5000,
          });
          setformDataPhysical({
            name: "",
            email: "",
            country_id: 1,
            city_name: "",
            postal_code: "",
            demo_account: "",
            birthday: "",
            gender: "",
            jmbg: "",
            mobile: "",
            document_number: "",
            accept_terms: "0",
            accept_policy: "0",
          });
          setformDataDomestic({
            name: "",
            email: "",
            country_id: 1,
            city_name: "",
            address: "",
            phone: "",
            company_work_position: "",
            company_contact_name: "",
            pib: "",
            maticni_broj: "",
            postal_code: "",
            mobile: "",
            accept_terms: "0",
            accept_policy: "0",
          });
          setformDataForeign({
            name: "",
            email: "",
            country_id: 1,
            city_name: "",
            address: "",
            phone: "",
            company_work_position: "",

            company_contact_name: "",
            pib: "",
            maticni_broj: "",
            postal_code: "",
            mobile: "",
            accept_terms: "0",
            accept_policy: "0",
          });
          setCheckedTerms(false);
          setCheckedPolicy(false);
          setLoading(true);
          setTimeout(() => {
            navigate(`/registracija/${params["*"]}/uspesno`);
            setLoading(false);
            setIsSuccess(true);
          }, 3000);
        } else {
          toast.error("Došlo je do greške!", {
            position: "top-center",
            autoClose: 3000,
          });
        }
      });
    },
  });

  return (
    <div className={`${classes.bg}`}>
      <div
        className={
          loading
            ? `fixed top-0 left-0 w-screen h-screen bg-black/30 flex flex-row gap-2 items-center justify-center  z-50`
            : `hidden`
        }
      >
        {" "}
        <i className={`fas fa-spinner fa-spin text-white text-3xl`}></i>
        <h1 className={`text-white text-3xl`}>Redirektujemo Vas...</h1>
      </div>
      <div className={`px-[3.13%] h-[7.5%] bg-[#232f3e]`}>
        <div className={`flex h-full items-center justify-start`}>
          <Link to={`/`} className={``}>
            <img src={Logo} alt="logo" width={300} height={250} />
          </Link>
        </div>
      </div>
      {params["*"] === "fizicko-lice" && (
        <div className={`h-full min-h-screen`}>
          <DemoRequestFormPhysical
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            formDataPhysical={formDataPhysical}
            checkedTerms={checkedTerms}
            setCheckedTerms={setCheckedTerms}
            checkedPolicy={checkedPolicy}
            setCheckedPolicy={setCheckedPolicy}
          />
        </div>
      )}
      {params["*"] === "domace-pravno-lice" && (
        <div className={`h-full min-h-screen`}>
          <DemoRequestFormDomestic
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            formDataDomestic={formDataDomestic}
            checkedTerms={checkedTerms}
            setCheckedTerms={setCheckedTerms}
            checkedPolicy={checkedPolicy}
            setCheckedPolicy={setCheckedPolicy}
          />
        </div>
      )}
      {params["*"] === "strano-pravno-lice" && (
        <div className={`h-full min-h-screen`}>
          <DemoRequestFormForeign
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            formDataForeign={formDataForeign}
            checkedTerms={checkedTerms}
            setCheckedTerms={setCheckedTerms}
            checkedPolicy={checkedPolicy}
            setCheckedPolicy={setCheckedPolicy}
          />
        </div>
      )}
      {isSuccess && <DemoRequestSuccess />}
    </div>
  );
};

export default DemoRequestForm;
