import Loader from "../../UI/Loader/Loader";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { currencyFormat } from "../../utils/functions";
import { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from "@mui/material";
import { icons } from "../../lib/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { get, post } from "../../services/api";
import { toast } from "react-toastify";

const SearchTable = ({
  data,
  limit,
  columns,
  setSort,
  translate_data,
  sort,
}) => {
  const navigate = useNavigate();
  const [company, setCompany] = useState(null);
  const [params, setParams] = useSearchParams();
  const filters_tmp = params.get("filters");
  const page_tmp = params.get("page");
  const search_tmp = params.get("search");

  const handleSort = (prop_name) => {
    //dodajemo objekat u niz u formatu:
    // field: prop_name, direction: "asc" ili "desc"
    //inicijalno je "asc"
    //ako je vec sortirano po tom polju, onda menjamo direction, a ako nije, dodajemo novi objekat
    //ako je direction desc, brisemo objekat iz niza
    let sort_tmp = [...sort];
    let index = sort_tmp.findIndex((el) => el.field === prop_name);
    if (index !== -1) {
      if (sort_tmp[index].direction === "asc") {
        sort_tmp[index].direction = "desc";
      } else {
        sort_tmp.splice(index, 1);
      }
    } else {
      sort_tmp.push({ field: prop_name, direction: "asc" });
    }

    setSort(sort_tmp);

    let sort_obj = sort_tmp
      ?.map((s) => {
        return `${s?.field}:${s?.direction}`;
      })
      .join("::");

    const newParams = {};
    if (search_tmp) {
      newParams.search = search_tmp;
    }
    if (filters_tmp) {
      newParams.filters = filters_tmp;
    }
    if (page_tmp) {
      newParams.page = page_tmp;
    }
    if (sort_obj) {
      newParams.sort = sort_obj;
    }
    setParams(newParams);
  };

  const renderPositionInSortArray = (prop_name) => {
    let index = sort?.findIndex((el) => el?.field === prop_name);
    if (index !== -1) {
      return <Typography variant={`caption`}>{index + 1}</Typography>;
    }
    return null;
  };

  return (
    <Box sx={{ mt: 1, mx: "2.75rem" }}>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              textTransform: "uppercase",
              bgcolor: "#232f3e",
              borderRadius: "10px",
            }}
          >
            <TableRow>
              {columns?.[0]?.options?.map(
                ({ name, id, visible, prop_name, currency, separate }, i) => {
                  if (visible) {
                    return (
                      <TableCell
                        onClick={() => handleSort(prop_name)}
                        key={id}
                        align={currency || separate ? `right` : `center`}
                        sx={{
                          cursor: "pointer",
                          color: "white",
                          fontWeight: "bold",
                          padding: "8px",
                          "&:first-child": {
                            borderTopLeftRadius: "10px",
                            borderBottomLeftRadius: "10px",
                          },
                          "&:last-child": {
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                          },
                        }}
                      >
                        <div
                          className={`!flex ${
                            currency || separate
                              ? "!text-right"
                              : "!text-center"
                          }  ${
                            currency || separate
                              ? "justify-end !pr-[9px]"
                              : "justify-center"
                          } !items-center !gap-2`}
                        >
                          {sort?.length > 1 &&
                            renderPositionInSortArray(prop_name)}

                          {translate_data?.[prop_name] ?? name}
                          {currency && (
                            <span className={`text-red-500`}>*</span>
                          )}
                          {sort?.find((el) => el?.field === prop_name) ? (
                            <img
                              className={`invert transition-all duration-300 ${
                                sort?.find((el) => el?.field === prop_name)
                                  ?.direction === "asc"
                                  ? "rotate-180"
                                  : ""
                              }`}
                              src={`/sort.png`}
                              width={10}
                              height={10}
                            />
                          ) : null}
                        </div>
                      </TableCell>
                    );
                  } else {
                    return null;
                  }
                },
              )}
              <TableCell
                sx={{
                  padding: "8px",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ mt: 1 }}>
            {data?.map((item, index) => {
              return (
                <TableRow
                  onMouseEnter={() => {
                    setCompany(item);
                  }}
                  key={item.id}
                  sx={{
                    cursor: "pointer",
                    "&:nth-of-type(odd)": {
                      bgcolor: "#f9f9fb",
                    },
                    "&:hover": {
                      bgcolor: "#aa1d23",
                    },
                    "&:hover > td": {
                      color: "#fff",
                    },
                  }}
                >
                  {(columns?.[0]?.options ?? [])?.map(
                    ({ id, visible, prop_name, currency, separate }, i) => {
                      if (visible) {
                        return (
                          <TableCell
                            key={id}
                            sx={{
                              textAlign:
                                i === 0
                                  ? "left"
                                  : currency || separate
                                    ? "right"
                                    : "center",
                              py: 1,
                              fontSize: "13.2px",
                              fontWeight:
                                prop_name === "naziv" ||
                                prop_name === "scoring_ocena"
                                  ? 600
                                  : 400,
                            }}
                          >
                            <Link
                              to={`/company-profile/basic-data/${item?.firma_id}`}
                            >
                              <div
                                className={`py-0.5 text-[13.2px] ${
                                  prop_name === "scoring_ocena" &&
                                  "text-white rounded-lg"
                                }`}
                                style={{
                                  background:
                                    prop_name === "scoring_ocena"
                                      ? item?.["color"]
                                      : "transparent",
                                }}
                              >
                                {currency || separate
                                  ? currencyFormat(item?.[prop_name], "none")
                                  : item?.[prop_name]}
                              </div>
                            </Link>
                          </TableCell>
                        );
                      } else {
                        return null;
                      }
                    },
                  )}
                  <BookmarkCompany item={item} />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SearchTable;

const BookmarkCompany = ({ item }) => {
  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: ["follow"],
    mutationFn: async ({ id }) => {
      return await post(
        `/portal/companies/follow`,
        {
          company_id: id,
        },
        "USERS",
      )?.then((res) => {
        if (res?.payload && res?.code === 200) {
          toast.success(res?.payload?.message, {
            position: "top-center",
          });
          return res?.payload;
        } else {
          toast.error(res?.data?.message, { position: "top-center" });
          return {};
        }
      });
    },
  });

  const { refetch, data: is_following } = useQuery({
    queryKey: ["is_following", item?.firma_id],
    queryFn: async () => {
      return await get(
        `/portal/companies/follow/${parseInt(item?.firma_id)}`,
        "USERS",
      )?.then((res) => {
        if (res?.payload) {
          return res?.payload;
        } else {
          return {};
        }
      });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  return (
    <TableCell className={"group"}>
      <Tooltip title={is_following?.tooltip} placement={"left"}>
        <button
          onClick={() => {
            mutate({ id: parseInt(item?.firma_id) });
          }}
          className={`!size-5 ${
            is_following?.follow
              ? "!text-green-600"
              : "!text-transparent group-hover:!text-green-600"
          }`}
        >
          {is_following?.follow ? icons?.save_no_stroke : icons?.save_stroke}
        </button>
      </Tooltip>
    </TableCell>
  );
};
