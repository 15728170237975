import { Link } from "react-router-dom";
import classes from "../DemoRequest.module.css";
import { useContext } from "react";
import { userContext } from "../../../services/userContext";
const DemoRequestSuccess = () => {
  const { lang } = useContext(userContext);

  return (
    <div
      className={`flex ${classes.height}  flex-col items-center text-center justify-center`}
    >
      <div className={`flex flex-col items-center justify-center `}>
        <h1 className={`text-[19px] underline font-bold uppercase`}>
          Zahtev za demo nalog{" "}
          <span className={`text-[#aa1d23]`}>je uspešno kreiran!</span>{" "}
        </h1>
        <p className={`text-base mt-4`}>
          Potvrdu o kreiranom korisničkom nalogu ćete dobiti putem e-maila koji
          ste naveli u registracionoj formi.
          <br />
          Ukoliko mejl nije stigao u inbox obavezno proverite junk ili spam
          folder.
        </p>
        <Link to={`/`} className={`mt-8 text-base text-[#aa1d23] underline`}>
          Vrati se na početnu stranu sajta
        </Link>
      </div>
      <div className={`mt-[5rem]`}>
        <div className={`mt-[45px] self-end font-light text-center`}>
          <p>
            U cilju dodatnog informisanja,
            <br />u svakom trenutku nas možete kontaktirati pozivom na broj
            telefona:
          </p>
        </div>{" "}
        <a
          href={`tel:0111233331`}
          className={`font-medium borderb relative mt-2 hover:text-[#aa1d23]`}
        >
          011 1233 331
        </a>
      </div>{" "}
      <div className={` 3xl:mt-32  flex flex-col items-center justify-center`}>
        <div
          className={`mt-[10%] w-full relative separate2 flex justify-center items-center text-black text-base font-normal`}
        >
          <Link className={`relative hover:underline`} to={`/kontakt`}>
            Kontakt
          </Link>
          <Link
            className={`relative hover:underline`}
            to={`/politika-privatnosti`}
          >
            Politika privatnosti
          </Link>
          <Link className={`relative hover:underline`} to={`/o-nama`}>
            O nama
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DemoRequestSuccess;
